
import { defineComponent } from "vue";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";

export default defineComponent({
  name: "card-4",
  components: {},
  props: {
    cardClasses: String,

    avatar: String,

    online: Boolean,

    initials: String,

    color: String,

    name: String,

    position: String,

    avgEarnings: String,

    totalSales: String
  },
  setup() {
    const openDrawer = () => {
      const drawerEl = document.getElementById("kt_drawer_chat");

      if (!drawerEl) {
        return;
      }

      DrawerComponent.getInstance(drawerEl).toggle();
    };

    return {
      openDrawer
    };
  }
});
